import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'publicationStatus'
})
export class PublicationStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'active': {
        return 'Activa';
        break;
      }
      case 'closed': {
        return 'Finalizada';
        break;
      }
      case 'under_review': {
        return 'Bajo Revisión';
        break;
      }
      case 'paused': {
        return 'Pausada';
        break;
      }
      default: {
        return 'S/D';
        break;
      }
    }
  }
}
