import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from '../@guards/auth.guard';
import { Headers, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ActiveMeliAccount } from '../@shared/active_meliaccount';

@Injectable({
  providedIn: 'root'
})
export class MeliService {
  constructor(
    private http: HttpClient,
    private guard: AuthGuard,
    private activeMeliAccount: ActiveMeliAccount
  ) {
    this.activeMeliAccount.ActiveMeliAccountId.subscribe((id) => (this.meli_id = id));
  }
  meli_id: string;
  client_secret = 'WQoaAZ68ZKWLffxFGYlFHPKWv99j30Bt';
  authURL = environment.BACKEND_URL + '/auth/account';
  redirect_uri = environment.FRONTEND_URL + '/pages/assignaccount';

  authorize(MeliCode: string) {
    let any: any;
    let grant_type = 'authorization_code';

    let redirect_uri = this.redirect_uri;
    let code = MeliCode;
    return this.http
      .post(this.authURL, { grant_type, redirect_uri, code })
      .map((response: Response) => response);
  }
  getAccounts() {
    return this.http
      .get(environment.BACKEND_URL + '/accounts')
      .map((response: Response) => response);
  }
  getPublications(parameters: string) {
    return this.http
      .get(
        `${environment.BACKEND_URL}/items?ml_account_id=${this.meli_id}&per_page=100&${parameters}`
      )
      .map((response: Response) => response);
  }
  getPublicationsNextPage(nextPageUrl: string) {
    return this.http
      .get(`${nextPageUrl}&ml_account_id=${this.meli_id}&per_page=100`)
      .map((response: Response) => response);
  }

  getPublicationItem(item_id: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/items/${item_id}?ml_account_id=${this.meli_id}`)
      .map((response: Response) => response);
  }
  getItemDescription(item_id: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/items/${item_id}/description?ml_account_id=${this.meli_id}`)
      .map((response: Response) => response);
  }
  getItemShippingOptions(item_id: number) {
    return this.http
      .get(
        `${environment.BACKEND_URL}/items/${item_id}/shipping_options?ml_account_id=${this.meli_id}`
      )
      .map((response: Response) => response);
  }
  getItemVariations(item_id: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/items/${item_id}/variations?ml_account_id=${this.meli_id}`)
      .map((response: Response) => response);
  }
  getOrders(meli_id: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/orders?ml_account_id=${this.meli_id}&per_page=100`)
      .map((response: Response) => response);
  }
  getOrdersNextPage(nextPageUrl: string) {
    return this.http
      .get(`${nextPageUrl}&ml_account_id=${this.meli_id}&per_page=100`)
      .map((response: Response) => response);
  }
  getOrderMessages(orderId: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/orders/${orderId}/messages?ml_account_id=${this.meli_id}`)
      .map((response: Response) => response);
  }
  getDefaultOrderMessage() {
    return this.http
      .get(
        `${environment.BACKEND_URL}/account/default_order_messages?ml_account_id=${this.meli_id}`
      )
      .map((response: Response) => response);
  }
  updateDefaultOrderMessage(defaultMessageId: any, message: string) {
    return this.http
      .put(
        `${environment.BACKEND_URL}/account/default_order_messages/${defaultMessageId}?ml_account_id=${this.meli_id}`,
        {
          message
        }
      )
      .map((response: Response) => response);
  }
  sendOrderMessage(orderId: number, from: any, to: any, text: string) {
    return this.http
      .post(`${environment.BACKEND_URL}/orders/${orderId}/messages`, {
        ml_account_id: this.meli_id,
        from,
        to,
        text
      })
      .map((response: Response) => response);
  }
  getCategoryAttributes(cateogoryId: number) {
    return this.http
      .get(
        `${environment.BACKEND_URL}/categories/${cateogoryId}/attributes?ml_account_id=${this.meli_id}`
      )
      .map((response: Response) => response);
  }
  getFirstCategories() {
    return this.http
      .get(`${environment.BACKEND_URL}/categories`)
      .map((response: Response) => response);
  }
  getCategory(mlCategoryId: any) {
    return this.http
      .get(`${environment.BACKEND_URL}/categories/${mlCategoryId}`)
      .map((response: Response) => response);
  }
  getDraftItems(parameters) {
    return this.http
      .get(
        `${environment.BACKEND_URL}/draft_items?ml_account_id=${this.meli_id}&per_page=100&${parameters}`
      )
      .map((response: Response) => response);
  }
  getDescriptionTemplates() {
    return this.http
      .get(`${environment.BACKEND_URL}/item_description_templates?ml_account_id=${this.meli_id}`)
      .map((response: Response) => response);
  }
  getNextDraftItemsPage(nextPageUrl: string) {
    return this.http
      .get(`${nextPageUrl}&ml_account_id=${this.meli_id}&per_page=100`)
      .map((response: Response) => response);
  }
  changeItemStatus(itemId: number[], status: string) {
    return this.http
      .put(`${environment.BACKEND_URL}/items/status`, {
        ml_account_id: this.meli_id,
        items_ids: itemId,
        status: status
      })
      .map((response: Response) => response);
  }
  createNewDescriptionTemplate(template) {
    return this.http
      .post(`${environment.BACKEND_URL}/item_description_templates`, {
        ml_account_id: this.meli_id,
        ...template
      })
      .map((response: Response) => response);
  }
  deleteDescriptionTemplate(id) {
    return this.http
      .delete(
        `${environment.BACKEND_URL}/item_description_templates/${id}?ml_account_id=${this.meli_id}`,
        {}
      )
      .map((response: Response) => response);
  }
  updateDescriptionTemplate(template) {
    return this.http
      .put(`${environment.BACKEND_URL}/item_description_templates/${template.id}`, {
        ml_account_id: this.meli_id,
        ...template
      })
      .map((response: Response) => response);
  }
  publishItems(itemIds: number[]): Observable<any> {
    return this.http
      .post(`${environment.BACKEND_URL}/draft_items/items`, {
        ml_account_id: this.meli_id,
        draf_item_ids: itemIds
      })
      .map((response: Response) => response);
  }
  deleteDraftItems(itemIds: number[]): Observable<any> {
    return this.http
      .request('delete', `${environment.BACKEND_URL}/draft_items`, {
        body: {
          draf_item_ids: itemIds,
          ml_account_id: this.meli_id
        }
      })
      .map((response: Response) => response);
  }
  updateItemAssignedDescriptionTemplate(id, item_ids) {
    return this.http
      .put(`${environment.BACKEND_URL}/item_description_templates/${id}/items`, {
        ml_account_id: this.meli_id,
        item_ids
      })
      .map((response: Response) => response);
  }
  detachDescriptionsFromItems(item_ids) {
    return this.http
      .put(`${environment.BACKEND_URL}/item_description_templates/items`, {
        ml_account_id: this.meli_id,
        item_ids
      })
      .map((response: Response) => response);
  }

  patchItemValues(itemId: number, values: any): Observable<any> {
    if ('available_quantity' in values) {
      values.available_quantity = String(values.available_quantity);
    }

    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}`, {
        ml_account_id: this.meli_id,
        ...values
      })
      .map((response: Response) => response);
  }
  updateItemAttributes(itemId: number, attributes: any): Observable<any> {
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}`, {
        ml_account_id: this.meli_id,
        attributes
      })
      .map((response: Response) => response);
  }
  updateItemShipping(itemId: number, shipping: any) {
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}`, {
        ml_account_id: this.meli_id,
        shipping
      })
      .map((response: Response) => response);
  }
  updateItemCategory(itemId: number, category: any): Observable<any> {
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}`, {
        ml_account_id: this.meli_id,
        category_id: category
      })
      .map((response: Response) => response);
  }
  updateItemVariations(itemId: number, variations: any, pictures: any): Observable<any> {
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}`, {
        ml_account_id: this.meli_id,
        pictures,
        variations
      })
      .map((response: Response) => response);
  }
  updateImages(ml_pictures: any, itemId: any) {
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}?ml_account_id=${this.meli_id}`, {
        ml_pictures
      })
      .map((response: Response) => response);
  }
  refreshUserNotificationToken(fcm_token: string) {
    return this.http
      .post(`${environment.BACKEND_URL}/auth/fcm`, {
        fcm_token
      })
      .map((response: Response) => response);
  }
  updateItemDescription(itemId: number, values: any) {
    let plain_text = values.description;
    return this.http
      .put(`${environment.BACKEND_URL}/items/${itemId}/description`, {
        ml_account_id: this.meli_id,
        description: plain_text
      })
      .map((response: Response) => response);
  }

  addPicture(itemId: number, picture: any) {
    const uploadURL = `${environment.BACKEND_URL}/items/${itemId}/picture-file?ml_account_id=${this.meli_id}`;
    const formData = new FormData();
    formData.append('picture', picture);

    return this.http.post(uploadURL, formData).map((response: Response) => response);
  }
  processNormalExcel(file: any) {
    const uploadURL = `${environment.BACKEND_URL}/draft_items/import?ml_account_id=${this.meli_id}`;
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(uploadURL, formData).map((response: Response) => response);
  }
}
