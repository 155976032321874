import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
  NbWindowService
} from '@nebular/theme';
import * as firebase from 'firebase/app';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { LoginService } from '../../../@shared/login.service';
import { Router } from '@angular/router';
import { ActiveUserService } from '../../../@shared/active-user.service';
import { environment } from '../../../../environments/environment';
import { MeliService } from '../../../@controllers/meli-controller';
import { Notification } from '../../../@models/notification';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from '../../../@shared/notifications.service';
import { ActiveMeliAccount } from '../../../@shared/active_meliaccount';
import { ActualDescriptionTemplates } from '../../../@shared/actual-description-templates';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  currentMessage = new BehaviorSubject(null);

  public notifications: Notification[] = [];
  public notificationsNumber: number = 0;
  themes = [
    {
      value: 'default',
      name: 'Light'
    },
    {
      value: 'dark',
      name: 'Dark'
    },
    {
      value: 'cosmic',
      name: 'Cosmic'
    },
    {
      value: 'corporate',
      name: 'Corporate'
    }
  ];

  currentTheme = 'default';
  selectedMLAccount: any;
  mlAccounts: any[] = [];
  userMenu = [{ title: 'Perfil' }, { title: 'Salir' }];
  meli_id: any;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private loginController: LoginService,
    private route: Router,
    private dataService: ActiveUserService,
    private activeMeliId: ActiveMeliAccount,
    private loginService: LoginService,
    private meliService: MeliService,
    private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationsService,
    private descriptionTemplateService: ActualDescriptionTemplates,
    private toastrService: NbToastrService,
    push: SwPush
  ) {
    // this.activeMeliId.ActiveMeliAccountId.subscribe((id) => {
    //   this.meli_id = id;
    //   this.selectedMLAccount = parseInt(this.meli_id);
    // });
    //this.getMLAccounts();
    // if ((this.meli_id = '0')) {
    //   this.meli_id = localStorage.getItem('meli_id');
    //   //this.selectedMLAccount = this.meli_id;
    //   this.activeMeliId.updateMeliAccountId(this.meli_id);
    // }
    this.dataService.ActiveUser.subscribe((u) => {
      this.user = u;
    });

    // if (!firebase.apps.length) {
    //   firebase.initializeApp(environment.firebase);
    //   navigator.serviceWorker.getRegistration().then((swr) => {
    //     if (firebase.messaging.isSupported()) {
    //       firebase.messaging().useServiceWorker(swr);
    //     }
    //   });
    // }
    // this.angularFireMessaging.messaging.subscribe(
    //   (messagingContext) => {
    //     messagingContext.onMessage = messagingContext.onMessage.bind(messagingContext);
    //     messagingContext.onTokenRefresh = messagingContext.onTokenRefresh.bind(messagingContext);
    //   }
    // );
    //this.permitToNotify();
    //this.monitorRefresh();
    //this.receiveMessage();
    //this.loadDescriptionTemplates();
    // push.messages.subscribe((msg) => {
    //   console.log(msg);
    //   let receivedMessage = new Notification(
    //     msg['data']['title'],
    //     msg['data']['message'],
    //     new Date(),
    //     ''
    //   );
    //   this.notifications.unshift(receivedMessage);
    //   this.notificationsNumber = this.notificationsNumber + 1;
    // });
    //push.notificationClicks.subscribe((click) => console.log('notification click', click));
    //this.activeMeliId.updateMeliAccountId(this.meli_id);
  }
  // setSelectedAccount(id) {
  //   this.activeMeliId.updateMeliAccountId(id);
  //   this.toastrService.success('Cuenta activa modificada, siga operando normalmente', 'Correcto');
  //   window.location.reload();
  // }
  // getMLAccounts() {
  //   this.meliService.getAccounts().subscribe(
  //     (data: any) => {
  //       this.activeMeliId.updateMeliAccountId(this.meli_id);
  //       this.mlAccounts = data;
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
  // receiveMessage() {
  //   if (firebase.messaging.isSupported()) {
  //     this.angularFireMessaging.messages.subscribe((payload) => {
  //       console.log('Payload', payload);
  //       this.currentMessage.next(payload);
  //     });
  //   }
  // }
  // loadDescriptionTemplates() {
  //   this.meliService.getDescriptionTemplates().subscribe((data: any) => {
  //     this.descriptionTemplateService.setTemplates(data['data']);
  //   });
  // }
  // permitToNotify() {
  //   if (firebase.messaging.isSupported()) {
  //     const messaging = firebase.messaging();
  //     messaging
  //       .requestPermission()
  //       .then(() =>
  //         messaging.getToken().then((token) => {
  //           this.meliService.refreshUserNotificationToken(token).subscribe(
  //             (data) => {},
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //         })
  //       )
  //       .catch((err) => {
  //         console.log('Unable to get permission to notify.', err);
  //       });
  //   }
  // }
  // monitorRefresh() {
  //   if (firebase.messaging.isSupported()) {
  //     let messaging = firebase.messaging();

  //     messaging.onTokenRefresh(() => {
  //       messaging
  //         .getToken()
  //         .then((refreshedToken) => {
  //           this.meliService.refreshUserNotificationToken(refreshedToken).subscribe(
  //             (data) => {},
  //             (error) => {}
  //           );
  //         })
  //         .catch((err) => console.log(err, 'Unable to retrieve new token'));
  //     });
  //   }
  // }
  ngOnInit() {
    /*this.notificationService.getFilteredNotifications('false').subscribe(
      (data) => {
        data['data'].forEach((notification) => {
          let newNotification = new Notification(
            notification.title,
            notification.description.replace(/<\/?[^>]+(>|$)/g, ''), // Remove HTML.
            notification.datetime,
            notification.link
          );
          this.notifications.unshift(newNotification);
        });
        this.notificationsNumber = this.notifications.length;
      },
      (error) => {
        console.log(error);
      }
    );*/
    this.currentTheme = this.themeService.currentTheme;
    if (!this.user) {
      this.loginService.getUser().subscribe(
        (data) => {
          this.dataService.updateUser(data);
          //console.log(this.user);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    // this.userService
    //   .getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.menuService.onItemClick().subscribe((event) => {
      this.onContextItemSelection(event.item.title);
    });
  }
  onContextItemSelection(title) {
    if (title === 'Salir') {
      this.loginController.logout();
      this.route.navigate(['/auth/login']);
    }
    if (title === 'Perfil') {
      this.route.navigate(['/pages/perfil/']);
    }
    if (title === 'Vincular cuenta') {
      this.authorize();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  authorize() {
    //window.location.href = `https://auth.mercadolibre.com.uy/authorization?response_type=code&client_id=${environment.APP_KEY}`;
  }

  loadMoreNotifications() {
    console.log('TODO: load more notifications');
  }

  viewActivity() {
    this.route.navigate(['pages/actividad']);
  }

  markAsRead() {
    this.notificationService.markAsRead().subscribe(
      (data) => {
        this.notificationsNumber = 0;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
