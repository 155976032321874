import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from './@guards/auth.guard';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class httpsInterceptor implements HttpInterceptor {
  constructor(private guard: AuthGuard, private route: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const secureReq = req.clone({
      url: req.url.replace('http://', 'http://')
     // ,headers: req.headers.set('Authorization', `Bearer ${this.guard.getToken()}`)
    });
    return next.handle(secureReq).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.guard.revokeAccess();
            this.route.navigate(['/auth/login']);
          }
        }
      )
    );
  }
}
