import { Injectable } from "@angular/core";

import { SwUpdate } from "@angular/service-worker";

@Injectable()
export class UpdateService {
  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.available.subscribe(evt => {
      if (
        alert(
          "Hay una nueva versión de la aplicación disponible. Se actualizará la aplicación."
        ) === null
      ) {
      } else window.location.reload();
    });
  }
}
