import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { AuthGuard } from '../@guards/auth.guard';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private http: HttpClient, private guard: AuthGuard) {}

  getFilteredNotifications(read: string) {
    return this.http
      .get(`${environment.BACKEND_URL}/notifications`, {
        headers: this.getCommonHeaders().toJSON(),
        params: { read }
      })
      .map((response: Response) => response);
  }
  getNextPageFilteredNotifications(read: string, page: number) {
    return this.http
      .get(`${environment.BACKEND_URL}/notifications?page=${page.toString()}`, {
        headers: this.getCommonHeaders().toJSON(),
        params: { read }
      })
      .map((response: Response) => response);
  }
  getAllNotifications() {
    return this.http
      .get(`${environment.BACKEND_URL}/notifications`, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  markAsRead() {
    return this.http
      .put(`${environment.BACKEND_URL}/notifications/markallread`, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  getNextPageAllNotificationPage(nextPageUrl: string) {
    return this.http
      .get(nextPageUrl, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  // TODO: REFACTOR THIS METHOD TO SOMEWHERE SHARED!!
  private getCommonHeaders(): Headers {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const isLogged = this.guard.isLoggedIn();
    if (isLogged) {
      headers.append('Authorization', 'Bearer ' + this.guard.getToken());
    } else {
      headers.append('X-Requested-With', 'XMLHttpRequest');
    }
    return headers;
  }
}
