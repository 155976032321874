import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../@models/user.model';
import { AuthGuard } from '../@guards/auth.guard';
import { Headers, Response } from '@angular/http';
import 'rxjs/add/operator/map';
const shajs = require('sha.js')
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private guard: AuthGuard) {}

  login(user: User) {
    var auth = btoa(user.nombreUsuario + ":" + shajs('sha256').update(user.password).digest('hex'));

    var headers = { "Authorization": "Basic " + auth };
    return this.http
      .get(environment.BACKEND_URL + '/sessions/login', {
        headers: headers
      })
      .map((response: Response) => response);
  }
  getUser() {
    return this.http
      .get(environment.BACKEND_URL + '/usuarios/id=' + localStorage.getItem('id'), {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  signUp(user: User) {
    return this.http
      .post(environment.BACKEND_URL + '/auth/signup', user, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  passwordReset(user: User) {
    return this.http
      .post(environment.BACKEND_URL + '/password/create', user, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }

  resetPassword(user: User) {
    return this.http
      .post(environment.BACKEND_URL + '/password/reset', user, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  findResetToken(token: string) {
    return this.http
      .get(environment.BACKEND_URL + '/password/find/' + token, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  activateAccount(token: string) {
    return this.http
      .get(environment.BACKEND_URL + '/auth/signup/activate/' + token, {
        headers: this.getCommonHeaders().toJSON()
      })
      .map((response: Response) => response);
  }
  logout() {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('AccessTok', '');
  }
  updateUser(values: any, userId: any){
    let changePassword = {newPassword:values.password_confirmation,oldPassword:values.old_password}
      return this.http
        .put(environment.BACKEND_URL + '/usuarios/changePassword/' + userId.toString(),
        changePassword,
        {
          headers: this.getCommonHeaders().toJSON(),

        })
        .map((response: Response) => response);

  }

  private getCommonHeaders(): Headers {
    const headers = new Headers();
    //headers.append('Content-Type', 'application/json');
    const isLogged = this.guard.isLoggedIn();
    if (isLogged) {
      headers.append('Token', this.guard.getToken());
      headers.append('ID',localStorage.getItem('id'))
    } else {
      headers.append('X-Requested-With', 'XMLHttpRequest');
    }
    return headers;
  }
}


