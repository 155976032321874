import { Injectable } from '@angular/core';
import { User } from '../@models/user.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActualDescriptionTemplates {
  ActiveDescriptionTemplates: BehaviorSubject<any[]>;
  descriptionTemplates: any[] = [];
  constructor() {
    this.ActiveDescriptionTemplates = new BehaviorSubject(this.descriptionTemplates);
  }

  setTemplates(templates: any) {
    this.descriptionTemplates = templates;
    this.ActiveDescriptionTemplates.next(templates);
  }

  clearTemplates() {
    this.ActiveDescriptionTemplates.next(null);
  }

  addTemplate(template: any) {
    this.descriptionTemplates.push(template);
    this.ActiveDescriptionTemplates.next(this.descriptionTemplates);
  }
}
