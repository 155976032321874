import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Subject } from 'rxjs/Subject';
import { SwPush } from '@angular/service-worker';
import { environment } from '../../environments/environment';

@Injectable()
export class MessagingService {
  private messaging = firebase.messaging();

  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable(); // message observable to show in Angular component
  push: SwPush;

  constructor() {}
  getPermission() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => messaging.getToken().then((token) => console.log(token)))
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
  }

  // Listen for token refresh
  monitorRefresh() {
    this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then((refreshedToken) => {
          console.log('Token refreshed.' + refreshedToken);
        })
        .catch((err) => console.log(err, 'Unable to retrieve new token'));
    });
  }
  initialize() {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
      navigator.serviceWorker
        .getRegistration()
        .then((swr) => firebase.messaging().useServiceWorker(swr));
    }
  }
  // save the permission token in firestore
  //   private saveToken(user, token): void {
  //     const currentTokens = user.fcmTokens || {};

  //     // If token does not exist in firestore, update db
  //     if (!currentTokens[token]) {
  //       const userRef = this.afs.collection('users').doc(user.uid);
  //       const tokens = { ...currentTokens, [token]: true };
  //       userRef.update({ fcmTokens: tokens });
  //     }
  //   }
  // used to show message when app is open
  receiveMessages() {
    this.push.messages.subscribe((msg) => console.log('push message', msg));
    this.push.notificationClicks.subscribe((click) => console.log('notification click', click));
  }
}
