import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../@models/user.model';
import { MeliService } from '../@controllers/meli-controller';

@Injectable({ providedIn: 'root' })
export class ActiveMeliAccount implements OnInit {
  ActiveMeliAccountId: BehaviorSubject<string>;
  constructor() {
    this.ActiveMeliAccountId = new BehaviorSubject('0');
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  clearMessage() {
    this.ActiveMeliAccountId.next(null);
  }

  updateMeliAccountId(id: any) {
    console.log('EMITIENDO');
    localStorage.setItem('meli_id', id);
    this.ActiveMeliAccountId.next(id);
  }
}
