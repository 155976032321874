import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActiveUserService {
  ActiveUser: BehaviorSubject<any>;
  constructor() {
    this.ActiveUser = new BehaviorSubject(null);
  }

  clearMessage() {
    this.ActiveUser.next(null);
  }

  updateUser(user: any) {
    // const meli_id = localStorage.getItem('meli_id');
    // console.log('TODO: updateUser in @shared/active-user.ts');
    // console.table('User is', user);
    this.ActiveUser.next(user);
  }
}
